// Store
import store from "@/store/index";

export interface AppCoachDiagnosticsCategoriesListFormCreate {
  organization_id: string;
  content: {
    name: string;
    fields: any;
  };
}

export class AppCoachDiagnosticsCategoriesListFormCreate implements AppCoachDiagnosticsCategoriesListFormCreate {
  organization_id: string;
  content: {
    name: string;
    fields: any;
  };

  constructor() {
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.content = {
      name: "",
      fields: [],
    };
  }
}