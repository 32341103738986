
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachDiagnosticsNavigationVue from "@/components/coach/diagnostics/navigation.vue";
import AppCoachDiagnosticsCategoriesListIndexVue from "@/views/app/coach/diagnostics/categories/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachDiagnosticsNavigationVue,
    AppCoachDiagnosticsCategoriesListIndexVue,
  },
})
export default class AppCoachDiagnosticsCategoriesIndexVue extends Vue {}
